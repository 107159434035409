import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6LfOX2cfAAAAAOrekUmQ0FDbAMFkzUL6gxacP342"
    scriptProps={{
      async: true, // optional, default to false,
      defer: true, // optional, default to false
      appendTo: 'body', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
