import React, { Suspense, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";

import { BackTop, Layout, Menu, Spin } from 'antd';
import { HomeOutlined, ShoppingOutlined, BookOutlined, ContactsOutlined } from '@ant-design/icons';
import { Route, Link, Routes, Navigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { CaretUpOutlined } from '@ant-design/icons';


import './App.less';

const Home = React.lazy(() => import("./views/Home"));
const Catalog = React.lazy(() => import("./views/Catalog"));
const Contact = React.lazy(() => import("./views/Form"));
const Product = React.lazy(() => import("./views/Product"));
const Documentation = React.lazy(() => import("./views/Documents"));

function App() {
  const location = useLocation();
  const { Header, Content, Footer } = Layout;

  const [key, setKey] = useState<string>("/");

  useEffect(() => {
    const lastIdx = location.pathname.lastIndexOf('/');
    setKey(lastIdx > 0 ? location.pathname.substring(0, location.pathname.lastIndexOf('/')) : location.pathname);
  }, [location])


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href={process.env.PUBLIC_URL + location.pathname} />
      </Helmet>
      <Layout className="layout">
        <Header style={{
          width: '100%', background: '#fff',
          position: (key === "/" || key === "/home" ? 'sticky' : 'relative'), top: 0, zIndex: 1000
        }}
          className="header-layout">
          <Link to="/home"><img src={process.env.PUBLIC_URL + '/logo_100x100.png'} alt="Werther CH" className='logo' /></Link>
          <Menu mode="horizontal" selectedKeys={[key]} className="menu">
            <Menu.Item key="/home" icon={<HomeOutlined />} className="home-menu">
              <Link to="/home">Accueil</Link>
            </Menu.Item>
            <Menu.Item key="/catalog" icon={<ShoppingOutlined />}>
              <Link to="/catalog">Produits</Link>
            </Menu.Item>
            <Menu.Item key="/doc" icon={<BookOutlined />}>
              <Link to="/doc">Documentations</Link>
            </Menu.Item>
            <Menu.Item key="/contact" icon={<ContactsOutlined />}>
              <Link to="/contact">Contact</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Content className="site-layout">
          <Suspense fallback={<Spin size="large" className='centered-loader' />}>
            <Routes>
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/home" element={<Home />} />
              <Route path="/catalog" element={<Catalog />}>
                <Route path=":type" element={<Catalog />} />
                <Route index element={<Catalog />} />
              </Route>
              <Route path="/doc" element={<Documentation />} />
              <Route path="/product/:id" element={<Product />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
          </Suspense>
        </Content>
        <Footer style={{ textAlign: 'center' }}>© 2021 Werther – Suisse – Tous les droits réservés</Footer>
      </Layout>
      <BackTop visibilityHeight={100}>
        <div className='backtop'><CaretUpOutlined /></div>
      </BackTop>
    </>
  );
}

export default App;
